import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { BrowserView, MobileView } from 'react-device-detect';
import store from './store';

import NAV_PAGES from './Consts/NavigationPages';
import Header from './Components/Header';
import MobileErrorMessage from './Components/MobileErrorMessage';
import VersionText from './Components/VersionText';
import SignIn from './Pages/SignIn';
import InstructionsPage from './Pages/InstructionsPage';
import TestInstructionsPage from './Pages/TestInstructionsPage';
import HearingTech from './Pages/HearingTech';
import Calibration from './Pages/Calibration';
import ConditionPage from './Pages/ConditionPage';
import TestPage from './Pages/TestPage';
import ListeningDifficultyPage from './Pages/ListeningDifficultyPage';
import FinishedPage from './Pages/FinishedPage';
import INSTRUCTIONS from './Consts/Instructions';

function App() {
  const [navigationPage, setNavigationPage] = useState(NAV_PAGES.SIGN_IN);

  const currentPage = () => {
    switch (navigationPage) {
      case NAV_PAGES.SIGN_IN:
        return (
          <SignIn
            setNav={setNavigationPage}
          />
        );
      case NAV_PAGES.QUIET_ROOM:
        return (
          <InstructionsPage
            instructions={INSTRUCTIONS.QUIET_ROOM}
            nextPage={NAV_PAGES.HEARING_TECH}
            setNav={setNavigationPage}
          />
        );
      case NAV_PAGES.HEARING_TECH:
        return (
          <HearingTech
            nextPage={NAV_PAGES.MAX_VOLUME}
            setNav={setNavigationPage}
          />
        );
      case NAV_PAGES.MAX_VOLUME:
        return (
          <InstructionsPage
            instructions={INSTRUCTIONS.MAX_VOLUME}
            nextPage={NAV_PAGES.CALIBRATION}
            setNav={setNavigationPage}
          />
        );
      case NAV_PAGES.CALIBRATION:
        return (
          <Calibration
            nextPage={NAV_PAGES.TEST_INSTRUCTIONS}
            setNav={setNavigationPage}
          />
        );
      case NAV_PAGES.TEST_INSTRUCTIONS:
        return (
          <TestInstructionsPage
            nextPage={NAV_PAGES.CONDITION}
            setNav={setNavigationPage}
          />
        );
      case NAV_PAGES.CONDITION:
        return (
          <ConditionPage
            nextPage={NAV_PAGES.TEST}
            setNav={setNavigationPage}
          />
        );
      case NAV_PAGES.TEST:
        return (
          <TestPage
            setNav={setNavigationPage}
          />
        );
      case NAV_PAGES.LISTENING_DIFFICULTY:
        return (
          <ListeningDifficultyPage
            setNav={setNavigationPage}
          />
        );
      case NAV_PAGES.FINISHED:
        return (
          <FinishedPage />
        );
      default:
        return <p>Error: nav page not defined</p>;
    }
  };

  return (
    <Provider store={store}>
      <Header />
      <BrowserView>
        {currentPage()}
      </BrowserView>
      <MobileView>
        <MobileErrorMessage />
      </MobileView>
      <VersionText />
    </Provider>
  );
}

export default App;
