/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const minimalPairsStvz = require('../Assets/minimalPairsStvz.json');
const minimalPairsFszdtsh = require('../Assets/minimalPairsFszdtsh.json');

const getTrialOptionsFromGroup = (group) => {
  switch (group) {
    case 0:
    case 1:
      return minimalPairsFszdtsh;
    case 2:
    case 3:
      return minimalPairsStvz;
    default:
      return null;
  }
};

const shuffle = (array) => {
  let currentIndex = array.length;
  let randomIndex;
  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }
  return array;
};

const numCarrierPhrases = 6;

const getTrial = (trialOption) => ({
  carrierPhrase: shuffle([...Array(numCarrierPhrases).keys()])[0],
  choices: trialOption.choices,
  correctAnswer: shuffle([...Array(trialOption.choices.length).keys()])[0],
  displayOrder: shuffle([...Array(trialOption.choices.length).keys()]),
  fileDirectory: trialOption.fileDirectory,
  speaker: shuffle([...Array(trialOption.numSpeakers).keys()])[0],
  trialId: trialOption.trial,
});

export const testSlice = createSlice({
  name: 'test',
  initialState: {
    trialIndex: 0,
    trials: null,
    block: 0,
    conditionIsWristband: null,
    blockStats: { count: 0, correct: 0 },
    numTrials: 112,
    numTrialsPerBlock: 28,
  },
  reducers: {
    incrementTrials: (state) => {
      state.trialIndex += 1;
    },
    setTrials: (state, action) => {
      const group = action.payload;
      const trialOptions = getTrialOptionsFromGroup(group).Items;
      const numTrialOptions = trialOptions.length;
      const trials = Array(state.numTrials);
      const orderedTrialIndices = [...Array(numTrialOptions).keys()];
      for (let i = 0; i < trials.length; i += 1) {
        if (i % numTrialOptions === 0) {
          shuffle(orderedTrialIndices);
        }
        const trialIndex = orderedTrialIndices[i % numTrialOptions];
        trials[i] = getTrial(trialOptions[trialIndex]);
      }
      state.trials = trials;
    },
    setCondition: (state, action) => {
      state.conditionIsWristband = action.payload;
    },
    toggleCondition: (state) => {
      state.conditionIsWristband = !state.conditionIsWristband;
    },
    setRandomCondition: (state) => {
      state.conditionIsWristband = Math.random() < 0.5;
    },
    incrementBlock: (state) => {
      state.block += 1;
    },
    addTrialToBlockStats: (state, action) => {
      state.blockStats.count += 1;
      if (action.payload) {
        state.blockStats.correct += 1;
      }
    },
    resetBlockStats: (state) => {
      state.blockStats.count = 0;
      state.blockStats.correct = 0;
    },
    setNumTrials: (state, action) => {
      state.numTrials = action.payload;
    },
    setNumTrialsPerBlock: (state, action) => {
      state.numTrialsPerBlock = action.payload;
    },
  },
});

export const {
  addTrialToBlockStats,
  incrementBlock,
  incrementTrials,
  resetBlockStats,
  setCondition,
  setNumTrials,
  setNumTrialsPerBlock,
  setRandomCondition,
  setTrials,
  toggleCondition,
} = testSlice.actions;

export default testSlice.reducer;
