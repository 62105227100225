/* eslint-disable no-promise-executor-return */

import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useSelector, useDispatch } from 'react-redux';

import {
  getDatabase, ref, push, set,
} from 'firebase/database';
import firebase from '../Firebase';

import {
  addTrialToBlockStats,
  incrementTrials,
  setTrials,
} from '../Reducers/TestSlice';

import NAV_PAGES from '../Consts/NavigationPages';
import { MIN_PAIR_AUDIO_ROOT, STUDIES } from '../Consts/Consts';
import COLORS from '../Styles/Colors';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const TrialText = styled.div`
  color: ${COLORS.TEXT_DARK};
  font-family: Muli;
  font-size: 24px;
  font-weight: 400;
  margin: 50px 0 16px;
  text-align: center;
`;

const ConditionText = styled.div`
  color: ${COLORS.TEXT_DARK};
  font-family: Muli;
  font-size: 24px;
  font-weight: 200;
  text-align: center;
`;

const OptionsContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const OptionsRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

function TestPage({ setNav }) {
  const dispatch = useDispatch();
  const sessionKey = useSelector((state) => state.session.sessionKey);
  const group = useSelector((state) => state.session.group);
  const testVolume = useSelector((state) => state.session.testVolume);

  const study = useSelector((state) => state.session.study);
  const isControlOnly = (
    study === STUDIES.CALIBRATION_CONTROL_ONLY || study === STUDIES.CONTROL_ONLY);

  const trialIndex = useSelector((state) => state.test.trialIndex);
  const trials = useSelector((state) => state.test.trials);
  const numTrials = useSelector((state) => state.test.numTrials);
  const numTrialsPerBlock = useSelector((state) => state.test.numTrialsPerBlock);
  const conditionIsWristband = useSelector((state) => state.test.conditionIsWristband);

  const [canPlay, setCanPlay] = useState(true);
  const [canAnswer, setCanAnswer] = useState(false);
  const [unlockTime, setUnlockTime] = useState();
  const [trialIsLoading, setTrialIsLoading] = useState(false);
  const [playError, setPlayError] = useState(false);

  if (!trials) {
    dispatch(setTrials(group));
  }

  const goToPage = (page) => {
    setNav(page);
  };

  const onAudioEnded = () => {
    setCanAnswer(true);
    setUnlockTime(new Date().getTime());
  };

  const linearVolumeFromDb = (dbVolume) => 10 ** (dbVolume / 20);

  const onAudioLoaded = (carrierPhraseAudio) => {
    setTrialIsLoading(false);
    carrierPhraseAudio.play();
  };

  const onPlay = () => {
    setCanPlay(false);
    setTrialIsLoading(true);
    const trial = trials[trialIndex];
    const {
      carrierPhrase,
      choices,
      correctAnswer,
      fileDirectory,
      speaker,
    } = trial;
    const carrierPhraseUrl = `${MIN_PAIR_AUDIO_ROOT}/carrier_phrases/carrier_phrase_${carrierPhrase}.wav`;
    const carrierPhraseAudio = new Audio(carrierPhraseUrl);
    carrierPhraseAudio.addEventListener('error', async () => {
      setPlayError(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setPlayError(false);
      setTrialIsLoading(false);
      setCanPlay(true);
    });
    carrierPhraseAudio.volume = linearVolumeFromDb(testVolume);
    const targetWordAudioUrl = `${MIN_PAIR_AUDIO_ROOT}/${fileDirectory}/${choices[correctAnswer]}_${speaker}.wav`;
    const targetWordAudio = new Audio(targetWordAudioUrl);
    targetWordAudio.addEventListener('error', async () => {
      setPlayError(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setPlayError(false);
      setTrialIsLoading(false);
      setCanPlay(true);
    });
    targetWordAudio.volume = linearVolumeFromDb(testVolume);
    targetWordAudio.addEventListener('canplaythrough', () => {
      onAudioLoaded(carrierPhraseAudio);
    });
    carrierPhraseAudio.addEventListener('ended', () => {
      targetWordAudio.play();
    });
    targetWordAudio.addEventListener('ended', () => {
      onAudioEnded();
    });
  };

  const goToNextTrial = () => {
    setCanPlay(true);
    dispatch(incrementTrials());
  };

  const logAnswer = async (answer) => {
    const answerTime = new Date().getTime();
    const responseTime = answerTime - unlockTime;
    const testVolumeLinear = linearVolumeFromDb(testVolume);
    const trial = trials[trialIndex];
    const {
      carrierPhrase,
      choices,
      correctAnswer,
      displayOrder,
      fileDirectory,
      speaker,
      trialId,
    } = trial;
    const block = Math.floor(trialIndex / numTrialsPerBlock);
    const correct = displayOrder[answer] === correctAnswer;
    const db = getDatabase(firebase);
    const trialSessionRef = ref(db, `trials/${sessionKey}/`);
    const newTrialRef = push(trialSessionRef);
    await set(newTrialRef, {
      answerTime,
      block,
      carrierPhrase,
      choices,
      condition: (conditionIsWristband ? 'wristband' : 'control'),
      correctAnswer,
      correctAnswerWord: choices[correctAnswer],
      displayOrder,
      fileDirectory,
      responseTime,
      speaker,
      testVolume,
      testVolumeLinear,
      trialId,
      trialIndex,
      userAnswer: answer,
      userAnswerWord: choices[displayOrder[answer]],
      userIsCorrect: correct,
    });
  };

  const atBlockEnd = () => {
    goToPage(NAV_PAGES.LISTENING_DIFFICULTY);
  };

  const onAnswer = async (answer) => {
    setCanAnswer(false);
    const trial = trials[trialIndex];
    const { correctAnswer, displayOrder } = trial;
    const correct = displayOrder[answer] === correctAnswer;
    dispatch(addTrialToBlockStats(correct));
    logAnswer(answer);
    await new Promise((resolve) => setTimeout(resolve, 750));
    if ((trialIndex + 1) % numTrialsPerBlock === 0) {
      atBlockEnd();
      return;
    }
    goToNextTrial();
  };

  const answerButton = (buttonIndex) => {
    if (!trials) {
      return null;
    }
    const trial = trials[trialIndex];
    return (
      <Button
        color="primary"
        disabled={!canAnswer}
        onClick={() => onAnswer(buttonIndex)}
        size="large"
        style={{ margin: '20px 40px', minWidth: '120px' }}
        variant="outlined"
      >
        {trial.choices[trial.displayOrder[buttonIndex]]}
      </Button>
    );
  };

  const options = () => (
    <OptionsContainer>
      <OptionsRow>
        {answerButton(0)}
        {answerButton(1)}
      </OptionsRow>
      <OptionsRow>
        {answerButton(2)}
        {answerButton(3)}
      </OptionsRow>
    </OptionsContainer>
  );

  const getConditionText = () => {
    if (isControlOnly) {
      return (null);
    }
    return (
      <ConditionText>
        {`Your wristband should be ${conditionIsWristband ? 'on' : 'off'}`}
      </ConditionText>
    );
  };

  return (
    <Container>
      <Button
        color="primary"
        disabled={!canPlay || trialIsLoading}
        onClick={onPlay}
        size="large"
        style={{ margin: '20px' }}
        variant="contained"
      >
        Play
        {trialIsLoading && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
            color={playError ? 'error' : 'primary'}
          />
        )}
      </Button>
      {options()}
      <TrialText>
        {`Trial ${trialIndex + 1} of ${numTrials}`}
      </TrialText>
      <Box sx={{ width: '40%', minWidth: '400px', marginBottom: '30px' }}>
        <LinearProgress variant="determinate" value={((trialIndex + 1) / numTrials) * 100} />
      </Box>
      {getConditionText()}
    </Container>
  );
}

export default TestPage;
