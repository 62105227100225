/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    sessionKey: null,
    group: 0, // TODO: remove this after dev?
    testVolume: -12,
    study: null,
  },
  reducers: {
    setSessionKey: (state, action) => {
      state.sessionKey = action.payload;
    },
    setGroup: (state, action) => {
      state.group = action.payload;
    },
    setStudy: (state, action) => {
      state.study = action.payload;
    },
    setTestVolume: (state, action) => {
      state.testVolume = action.payload;
    },
  },
});

export const {
  setGroup,
  setSessionKey,
  setStudy,
  setTestVolume,
} = sessionSlice.actions;

export default sessionSlice.reducer;
