import React from 'react';
import styled from 'styled-components';
import COLORS from '../Styles/Colors';
import packageJson from '../../package.json';

const Container = styled.div`
  position: absolute;
  bottom: 0;
  margin-left: 10px;
`;

const VersionText = styled.p`
  font-size: 14px;
  font-family: Muli;
  font-weight: 200;
  color: ${COLORS.TEXT_DARK};
`;

function Header() {
  return (
    <Container>
      <VersionText>
        v
        {packageJson.version}
      </VersionText>
    </Container>
  );
}

export default Header;
