import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import LogoImage from '../Assets/neo_logo.png';
import COLORS from '../Styles/Colors';

const HeaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Logo = styled.img`
  align-self: flex-end;
  margin: 36px;
  width: 140px;

  ${({ smallLogo }) => smallLogo && `
    width: 90px;
  `}
`;

const TitleText = styled.p`
  font-size: 52px;
  font-family: Muli;
  font-weight: 200;
  text-align: center;
  color: ${COLORS.TEXT_DARK};
  margin: 40px;

  ${({ smallFont }) => smallFont && `
    font-size: 42px;
    margin: 20px;
  `}
`;

function Header() {
  return (
    <HeaderContainer>
      <Logo smallLogo={isMobile} src={LogoImage} alt="Neosensory Logo" />
      <TitleText smallFont={isMobile}>Clarify Speech Task</TitleText>
    </HeaderContainer>
  );
}

export default Header;
