import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import COLORS from '../Styles/Colors';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  color: ${COLORS.TEXT_DARK};
  font-family: Muli;
  font-size: 28px;
  font-weight: 200;
  margin: 20px 120px;
  text-align: center;
`;

function Instructions({ setNav, instructions, nextPage }) {
  const onNext = () => {
    setNav(nextPage);
  };

  const instructionsText = (
    <>
      {
        instructions.text.map((t) => (
          <Text key={t}>
            {t}
          </Text>
        ))
      }
    </>
  );

  return (
    <Container>
      {instructionsText}
      <Button
        color="primary"
        onClick={onNext}
        size="large"
        style={{ margin: '20px' }}
        variant="outlined"
      >
        {instructions.button}
      </Button>
    </Container>
  );
}

export default Instructions;
