import { initializeApp, getApps, getApp } from 'firebase/app';

export const firebaseConfig = {
  apiKey: 'AIzaSyAa0JdnWNVAxbWAqpyD9uTv-Mh9SMdWx4c',
  authDomain: 'minimal-pair-test.firebaseapp.com',
  databaseURL: 'https://minimal-pair-test-default-rtdb.firebaseio.com/',
  projectId: 'minimal-pair-test',
  storageBucket: 'minimal-pair-test.appspot.com',
  messagingSenderId: '518753906381',
  appId: '1:518753906381:web:5dca11d2c13fc6146ec384',
  measurementId: 'G-H64QGSXPN0',
};

export default !getApps.length ? initializeApp(firebaseConfig) : getApp();
