import { configureStore } from '@reduxjs/toolkit';
import testReducer from './Reducers/TestSlice';
import sessionReducer from './Reducers/SessionSlice';

export default configureStore({
  reducer: {
    test: testReducer,
    session: sessionReducer,
  },
});
