import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import COLORS from '../Styles/Colors';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  color: ${COLORS.TEXT_DARK};
  font-family: Muli;
  font-size: 28px;
  font-weight: 200;
  margin: 20px 120px;
  text-align: center;
`;

function FinishedPage() {
  const closeTab = () => {
    window.opener = null;
    window.open('', '_self');
    window.close();
  };

  return (
    <Container>
      <Text>
        You’re finished!
      </Text>
      <Text>
        Please remember to turn your computer’s volume back to a comfortable level.
      </Text>
      <Button
        color="primary"
        onClick={closeTab}
        size="large"
        style={{ marginBottom: '7px', marginTop: '20px' }}
        variant="outlined"
      >
        Close tab
      </Button>
    </Container>
  );
}

export default FinishedPage;
