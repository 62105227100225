import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { useSelector, useDispatch } from 'react-redux';
import COLORS from '../Styles/Colors';
import { STUDIES } from '../Consts/Consts';

import { setRandomCondition, setCondition } from '../Reducers/TestSlice';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  color: ${COLORS.TEXT_DARK};
  font-family: Muli;
  font-size: 28px;
  font-weight: 200;
  margin: 20px 120px;
  text-align: center;
`;

function ConditionPage({ setNav, nextPage }) {
  const onNext = () => {
    setNav(nextPage);
  };
  const dispatch = useDispatch();
  const block = useSelector((state) => state.test.block);
  const numTrialsPerBlock = useSelector((state) => state.test.numTrialsPerBlock);
  const study = useSelector((state) => state.session.study);

  const isControlOnly = (
    study === STUDIES.CALIBRATION_CONTROL_ONLY || study === STUDIES.CONTROL_ONLY);

  const conditionIsWristband = useSelector((state) => state.test.conditionIsWristband);
  if (conditionIsWristband === null) {
    if (isControlOnly) {
      dispatch(setCondition(false));
    } else {
      dispatch(setRandomCondition());
    }
  }

  const instructionsTextCopy = () => {
    if (study === STUDIES.CALIBRATION_CONTROL_ONLY) {
      return `If you have a Neosensory wristband, please do not use it during this study.
      The volume will adjust every ${numTrialsPerBlock} trials. If you cannot hear any words,
      make your best guess until the volume adjusts.`;
    }
    if (study === STUDIES.CONTROL_ONLY) {
      return 'If you have a Neosensory wristband, please do not use it during this study.';
    }
    return (block === 0)
      ? `Every ${numTrialsPerBlock} trials, you will be asked to turn your wristband on or off.
      Please begin with your wristband ${conditionIsWristband ? 'on' : 'off'}.`
      : `For the next ${numTrialsPerBlock} trials,
      please turn your wristband ${conditionIsWristband ? 'on' : 'off'}.`;
  };

  const instructionsText = () => {
    const text = instructionsTextCopy();
    return (
      <Text>
        { text }
      </Text>
    );
  };

  if (conditionIsWristband === null) {
    return (<div />);
  }

  return (
    <Container>
      {instructionsText()}
      <Button
        color="primary"
        onClick={onNext}
        size="large"
        style={{ margin: '20px' }}
        variant="outlined"
      >
        {isControlOnly
          ? 'Continue'
          : `My wristband is ${conditionIsWristband ? 'on' : 'off'}`}
      </Button>
    </Container>
  );
}

export default ConditionPage;
