const INSTRUCTIONS = {
  QUIET_ROOM: {
    text: [
      `Welcome to the Clarify Speech Task. This task will take about 15 to 20 minutes.
      During the test, you will be asked to listen to words and answer which word you heard.`,
      'Please make sure you are in a quiet room with no distractions for this task.',
    ],
    button: 'I’m in a quiet room',
  },
  HEARING_TECH: 'hearing_tech',
  MAX_VOLUME: {
    text: [
      `Please set your computer's volume as high as it can go for this test and make sure no other
      sounds are playing from your computer. Please use speakers and not headphones.`,
    ],
    button: 'My computer’s volume is at max',
  },
};

export default INSTRUCTIONS;
