import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import { Synth } from 'tone';
import { useSelector, useDispatch } from 'react-redux';

import {
  getDatabase, ref, set, update,
} from 'firebase/database';
import firebase from '../Firebase';

import { setTestVolume } from '../Reducers/SessionSlice';

import COLORS from '../Styles/Colors';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const SliderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 20px;
  max-width: 800px;
  width: 90%;
`;

const Text = styled.div`
  color: ${COLORS.TEXT_DARK};
  font-family: Muli;
  font-size: 28px;
  font-weight: 200;
  margin: 20px 120px;
  text-align: center;
`;

const TrialText = styled.div`
  color: ${COLORS.TEXT_DARK};
  font-family: Muli;
  font-size: 24px;
  font-weight: 200;
  text-align: center;
`;

// lerp01: map a from [0, 1] to [x, y]
const lerp01 = (x, y, a) => x * (1 - a) + y * a;

const synth = new Synth().toDestination();
const tone = 4000;

const initalSliderValue = 0;
const minVolumeDb = -80;
const maxVolumeDb = -2;

let bezier1;
let bezier2;
const resetBezierCurve = () => {
  bezier1 = lerp01(0.1, 0.9, Math.random());
  bezier2 = lerp01(0.1, 0.9, Math.random());
};
resetBezierCurve();

// https://www.desmos.com/calculator/gn2hkpv0z5
const bezierMapping = ((val) => 3 * ((1 - val) ** 2) * val * bezier1 + 3 * (1 - val)
  * (val ** 2) * bezier2 + (val ** 3));

const sliderValueToDbVolume = (val) => {
  const bezierVal = bezierMapping(val);
  return lerp01(minVolumeDb, maxVolumeDb, bezierVal);
};

const initalVolumeValueDb = sliderValueToDbVolume(initalSliderValue);
synth.volume.value = initalVolumeValueDb;

const chosenVolumes = [];

function Calibration({ setNav, nextPage }) {
  const dispatch = useDispatch();
  const sessionKey = useSelector((state) => state.session.sessionKey);

  const [isPlaying, setIsPlaying] = useState(false);
  const [value, setValue] = useState(initalSliderValue);
  const [canSubmit, setCanSubmit] = useState(false);

  const numTrials = 3;
  const [toneTrial, setToneTrial] = useState(0);

  const toNextPage = () => {
    setNav(nextPage);
  };

  const startTone = () => {
    synth.triggerAttack(tone);
    setIsPlaying(true);
  };

  const stopTone = () => {
    synth.triggerRelease();
    setIsPlaying(false);
  };

  const onVolumeSliderChanged = (event, val) => {
    setValue(val);
    const vol = sliderValueToDbVolume(val);
    if (!isPlaying) {
      startTone();
    }
    synth.volume.value = vol;
    setCanSubmit(val > 0);
  };

  const logTrial = (sliderValue, chosenVolume, trial) => {
    const db = getDatabase(firebase);
    const trialRef = ref(db, `calibrationData/${sessionKey}/trials/${trial}`);
    const trialTime = new Date().getTime();
    set(trialRef, {
      sliderValue,
      chosenVolume,
      bezier1: '0',
      bezier2: '0',
      trialTime,
    });
  };

  const calVolToTestVolSlope = 0.32161;
  const calVolToTestVolIntercept = -14.39936;
  const setTestVolumeFromCalibrationVolume = (calibrationVolume) => {
    let newVolume = calibrationVolume * calVolToTestVolSlope + calVolToTestVolIntercept;
    newVolume = Math.min(Math.max(newVolume, minVolumeDb), maxVolumeDb);
    dispatch(setTestVolume(newVolume));
  };

  const logOverallResults = async () => {
    const db = getDatabase(firebase);
    const averageVolume = chosenVolumes.reduce((a, b) => a + b) / chosenVolumes.length;
    setTestVolumeFromCalibrationVolume(averageVolume);
    const volumeRef = ref(db, `calibrationData/${sessionKey}`);
    await update(volumeRef, { averageVolume });
  };

  const onSubmit = async () => {
    const chosenVolume = sliderValueToDbVolume(value);
    chosenVolumes.push(chosenVolume);
    logTrial(value, chosenVolume, toneTrial);
    stopTone();
    setCanSubmit(false);
    setValue(0);
    synth.volume.value = minVolumeDb;
    if (toneTrial + 1 === numTrials) {
      await logOverallResults();
      toNextPage();
      return;
    }
    setToneTrial(toneTrial + 1);
    resetBezierCurve();
  };

  return (
    <Container>
      <Text>
        Slowly slide the slider below until you can just barely hear a tone playing.
        You will do this three times. The slider will be different each time.
      </Text>
      <SliderContainer>
        <Slider
          aria-labelledby="discrete-slider"
          max={1}
          min={0}
          onChange={onVolumeSliderChanged}
          step={0.001}
          value={value}
        />
      </SliderContainer>
      <Button
        color="primary"
        disabled={!canSubmit}
        onClick={onSubmit}
        size="large"
        style={{ margin: '20px' }}
        variant="outlined"
      >
        I can just barely hear the tone
      </Button>
      <TrialText>
        {`${toneTrial + 1}/${numTrials}`}
      </TrialText>
    </Container>
  );
}

export default Calibration;
