import React from 'react';
import styled from 'styled-components';
import COLORS from '../Styles/Colors';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 40px;
`;

const Text = styled.p`
  font-size: 20px;
  font-family: Muli;
  font-weight: 200;
  text-align: center;
  color: ${COLORS.TEXT_DARK};
`;

function MobileErrorMessage() {
  return (
    <Container>
      <Text>
        Please complete this task on your computer. This cannot be completed on a smartphone.
      </Text>
      <Text>
        Reach out to the Neosensory research team at participate@neosensory.com with any questions.
      </Text>
    </Container>
  );
}

export default MobileErrorMessage;
