import React, { useState } from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import {
  getDatabase, ref, get, child, push, set,
} from 'firebase/database';
import firebase from '../Firebase';

import { setSessionKey, setGroup, setStudy } from '../Reducers/SessionSlice';
import { setNumTrials, setNumTrialsPerBlock } from '../Reducers/TestSlice';

import { STUDIES } from '../Consts/Consts';
import NAV_PAGES from '../Consts/NavigationPages';
import packageJson from '../../package.json';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 40px;
`;

function SignIn({ setNav }) {
  const dispatch = useDispatch();

  const noIdFoundError = 'ID not found';
  const [signInError, setSignInError] = useState(false);
  const [signInErrorMessage, setSignInErrorMessage] = useState(' ');

  const [canSignIn, setCanSignIn] = useState(false);

  const [userId, setUserId] = useState('');

  const setError = (error) => {
    if (error) {
      setSignInErrorMessage(error);
      setSignInError(true);
    } else {
      setSignInErrorMessage(' ');
      setSignInError(false);
    }
  };

  const onChangePassword = (event) => {
    const newUserId = event.target.value;
    setUserId(newUserId);
    setCanSignIn(newUserId !== '');
    setError();
  };

  const getUser = async (id) => {
    const dbRef = ref(getDatabase(firebase));
    try {
      const user = await get(child(dbRef, `users/${id}`));
      return user.val();
    } catch (e) {
      return null;
    }
  };

  const logNewSession = async (user) => {
    const db = getDatabase(firebase);
    const sessionListRef = ref(db, 'sessions');
    const newSessionRef = push(sessionListRef);
    const signInTime = new Date().getTime();
    await set(newSessionRef, {
      appVersion: packageJson.version,
      signInTime,
      userGroup: user.group,
      userId,
      userStudy: user.study ?? STUDIES.DEFAULT,
    });
    dispatch(setSessionKey(newSessionRef.key));
  };

  const onSignIn = async () => {
    setCanSignIn(false);
    const user = await getUser(userId);
    if (user && user.isActive) {
      dispatch(setGroup(user.group));
      dispatch(setStudy(user.study ?? STUDIES.DEFAULT));
      if (user.study === STUDIES.CALIBRATION_CONTROL_ONLY) {
        dispatch(setNumTrialsPerBlock(16));
        dispatch(setNumTrials(160));
      }
      logNewSession(user);
      setNav(NAV_PAGES.QUIET_ROOM);
      return;
    }
    setError(noIdFoundError);
    setCanSignIn(true);
  };

  return (
    <Container>
      <TextField
        error={signInError}
        helperText={signInErrorMessage}
        id="user-id-input"
        key="user-id-input"
        label="Study ID"
        variant="outlined"
        onChange={onChangePassword}
      />
      <Button
        color="primary"
        onClick={onSignIn}
        size="large"
        style={{ margin: '20px' }}
        variant="outlined"
        disabled={!canSignIn}
      >
        Sign in
      </Button>
    </Container>
  );
}

export default SignIn;
