import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';

import {
  getDatabase, ref, update,
} from 'firebase/database';
import firebase from '../Firebase';

import COLORS from '../Styles/Colors';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  color: ${COLORS.TEXT_DARK};
  font-family: Muli;
  font-size: 28px;
  font-weight: 200;
  margin: 20px 120px;
  text-align: center;
`;

function HearingTech({ setNav, nextPage }) {
  const sessionKey = useSelector((state) => state.session.sessionKey);

  const onNext = async (tech) => {
    const db = getDatabase(firebase);
    const updates = {};
    updates[`sessions/${sessionKey}/hearingTech`] = tech;
    await update(ref(db), updates);
    setNav(nextPage);
  };

  return (
    <Container>
      <Text>
        If you normally wear hearing aids or cochlear implants when in conversation,
        you may wear them during this test.
      </Text>
      <Text>
        Please keep this the same each time you take this test.
      </Text>
      <Button
        color="primary"
        onClick={() => onNext('hearing_aids')}
        size="large"
        style={{ marginBottom: '7px', marginTop: '20px' }}
        variant="outlined"
      >
        I’m wearing hearing aids
      </Button>
      <Button
        color="primary"
        onClick={() => onNext('cochlear_implants')}
        size="large"
        style={{ margin: '7px' }}
        variant="outlined"
      >
        I’m wearing cochlear implants
      </Button>
      <Button
        color="primary"
        onClick={() => onNext('no_hearing_tech')}
        size="large"
        style={{ margin: '7px' }}
        variant="outlined"
      >
        I’m wearing neither
      </Button>
    </Container>
  );
}

export default HearingTech;
