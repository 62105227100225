import React from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import COLORS from '../Styles/Colors';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  color: ${COLORS.TEXT_DARK};
  font-family: Muli;
  font-size: 28px;
  font-weight: 200;
  margin: 20px 120px;
  text-align: center;
`;

function Instructions({ setNav, nextPage }) {
  const onNext = () => {
    setNav(nextPage);
  };

  const numTrials = useSelector((state) => state.test.numTrials);

  return (
    <Container>
      <Text>
        In this test, you will hear a sentence such as “Please select the word ‘teal’”.
        You will then see four options and should select the word that you heard.
      </Text>
      <Text>
        {`You will do this for ${numTrials} trials.`}
      </Text>
      <Button
        color="primary"
        onClick={onNext}
        size="large"
        style={{ margin: '20px' }}
        variant="outlined"
      >
        Begin test
      </Button>
    </Container>
  );
}

export default Instructions;
