import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import { useSelector, useDispatch } from 'react-redux';

import {
  getDatabase, ref, set,
} from 'firebase/database';
import firebase from '../Firebase';

import {
  incrementBlock,
  incrementTrials,
  resetBlockStats,
  toggleCondition,
} from '../Reducers/TestSlice';

import { setTestVolume } from '../Reducers/SessionSlice';

import COLORS from '../Styles/Colors';
import NAV_PAGES from '../Consts/NavigationPages';
import { STUDIES } from '../Consts/Consts';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const SliderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 20px;
  max-width: 800px;
  width: 90%;
`;

const Text = styled.div`
  color: ${COLORS.TEXT_DARK};
  font-family: Muli;
  font-size: 28px;
  font-weight: 200;
  margin: 20px 120px;
  text-align: center;
`;

const SliderDescriptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const SliderDescriptionText = styled.div`
  color: ${COLORS.TEXT_DARK};
  font-family: Muli;
  font-size: 24px;
  font-weight: 200;
  margin: 10px 0;
`;

function ListeningDifficultyPage({ setNav }) {
  const sessionKey = useSelector((state) => state.session.sessionKey);
  const testVolume = useSelector((state) => state.session.testVolume);
  const study = useSelector((state) => state.session.study);
  const isControlOnly = (
    study === STUDIES.CALIBRATION_CONTROL_ONLY || study === STUDIES.CONTROL_ONLY);

  const dispatch = useDispatch();
  const [value, setValue] = useState(0);

  const conditionIsWristband = useSelector((state) => state.test.conditionIsWristband);
  const numTrialsPerBlock = useSelector((state) => state.test.numTrialsPerBlock);
  const numTrials = useSelector((state) => state.test.numTrials);
  const block = useSelector((state) => state.test.block);
  const blockStats = useSelector((state) => state.test.blockStats);

  const onSliderChanged = (event, val) => {
    setValue(val);
  };

  const logDifficulty = async (sliderValue) => {
    const db = getDatabase(firebase);
    const blockRef = ref(db, `listeningDifficulty/${sessionKey}/blocks/${block}`);
    const timestamp = new Date().getTime();
    await set(blockRef, {
      sliderValue,
      timestamp,
      conditionIsWristband,
    });
  };

  const targetScore = 0.6;
  const calibrationJumpCoefficient = 35; // Move XdB for a score that is 100% away from target score
  const minVolumeDb = -80;
  const maxVolumeDb = -2;
  const adjustVolumeForCalibrationStudy = () => {
    const score = blockStats.correct / blockStats.count;
    const delta = score - targetScore;
    const jump = delta * calibrationJumpCoefficient;
    const newVolume = Math.min(Math.max(testVolume - jump, minVolumeDb), maxVolumeDb);
    dispatch(setTestVolume(newVolume));
    dispatch(setTestVolume(newVolume));
  };

  const setNewBlock = () => {
    dispatch(incrementBlock());
    dispatch(incrementTrials());
    if (!isControlOnly) {
      dispatch(toggleCondition());
    } else if (study === STUDIES.CALIBRATION_CONTROL_ONLY) {
      adjustVolumeForCalibrationStudy();
    }
    dispatch(resetBlockStats());
  };

  const navigateAway = () => {
    const totalNumBlocks = Math.floor(numTrials / numTrialsPerBlock);
    if (block + 1 === totalNumBlocks) {
      setNav(NAV_PAGES.FINISHED);
    } else {
      setNav(isControlOnly ? NAV_PAGES.TEST : NAV_PAGES.CONDITION);
    }
  };

  const onSubmit = async () => {
    await logDifficulty(value);
    setNewBlock();
    navigateAway();
  };

  return (
    <Container>
      <Text>
        {`Please rate how effortful it was to listen to the previous ${numTrialsPerBlock} trials.`}
      </Text>
      <SliderContainer>
        <Slider
          aria-labelledby="discrete-slider"
          max={10}
          marks
          min={0}
          onChange={onSliderChanged}
          step={1}
          style={{ width: '80%' }}
          value={value}
          valueLabelDisplay="auto"
        />
        <SliderDescriptionContainer>
          <SliderDescriptionText bold>
            Not at all difficult
          </SliderDescriptionText>
          <SliderDescriptionText>
            Extremely difficult
          </SliderDescriptionText>
        </SliderDescriptionContainer>
      </SliderContainer>
      <Button
        color="primary"
        onClick={onSubmit}
        size="large"
        style={{ margin: '20px' }}
        variant="outlined"
      >
        Submit
      </Button>
    </Container>
  );
}

export default ListeningDifficultyPage;
