const NAV_PAGES = {
  SIGN_IN: 'sign_in',
  QUIET_ROOM: 'quiet_room',
  HEARING_TECH: 'hearing_tech',
  MAX_VOLUME: 'max_volume',
  CALIBRATION: 'calibration',
  TEST_INSTRUCTIONS: 'test_instructions',
  CONDITION: 'condition',
  TEST: 'test',
  LISTENING_DIFFICULTY: 'listening_difficulty',
  FINISHED: 'finished',
};

export default NAV_PAGES;
